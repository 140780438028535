import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import Cookies from 'universal-cookie';


function Body() {
    const cookie = new Cookies()

    return (
        <>
            <div className="bodyWrapper" >
                <Container style={{ padding: 0 }}>
                    <Grid container fluid >
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: 0 }}>
                            <div className='bodyContainer'>
                                <p className='bodyTitle'>Trade cryptocurrency with <br></br>the universe</p>
                                <p className='bodyDescription'>All cryptocurrency exchange.</p>
                                {
                                    cookie.get('token') ?
                                        null
                                        :
                                        <div className='buttonsContainer'>
                                            <Link to="/signup" style={{ textDecoration: 'none', color: 'white' }}>
                                                <Button
                                                    variant="contained"
                                                    className='createAccount'
                                                >
                                                    Create Account
                                                </Button>
                                            </Link>

                                            <Link to="/markets" style={{ textDecoration: 'none', color: 'white' }}>
                                                <Button
                                                    variant="text"
                                                    className='bottom'
                                                >
                                                    View Market
                                                </Button>
                                            </Link>
                                        </div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </>
    )
}

export default Body