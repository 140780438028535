import { Button, Container, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './styles.css'
import { editUser, getUserById } from '../../api'
import Cookies from 'universal-cookie';
import moment from 'moment';
import { useFormik } from 'formik';
import Snackbars from '../../components/snackbar';

function Profile() {
    const cookie = new Cookies()
    const [user, setUser] = useState([])
    const [successopen, setSuccessOpen] = useState(false);
    const [pass, setPass] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };

    useEffect(() => {
        const user = getUserById(cookie.get('id'))
        user.then(response => {
            setUser(response.data)
            setPass(response.data.password)
        })
    }, [])


    const formik = useFormik({
        initialValues: {
            password: pass,
        },
        onSubmit: () => { }
    });
    const func = () => {
        if (user) {
            const data = {
                password: pass,
            }

            const updateUser = editUser(user._id, data)
            updateUser.then(response => {
                if (response.success) {
                    setSuccessOpen(true);
                } else {
                    alert(response.message)
                }
            })
        }

    }

    return (
        <Container >
            <Grid container fluid >
                <Grid lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'cetner', justifyContent: 'center' }}>
                    <div className='profileWrapper'>
                        <div className='profileContainer'>
                            <div className='profileTitleContainer'>
                                <p className='profileTitle'>Profile</p>
                            </div>
                            <div style={{ padding: 16 }}>
                                <div className='nameInputContainer'>
                                    <div>
                                        <p>First Name</p>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            label={user.firstName}
                                            variant="outlined"
                                            style={{ width: "100%" }} />
                                    </div>
                                    <div style={{ marginLeft: 16 }}>
                                        <p>Last Name</p>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            label={user.lastName}
                                            variant="outlined" />
                                    </div>
                                </div>
                                <div className='emailInputContainer'>
                                    <div>
                                        <p>Email</p>
                                        <TextField
                                            size="small"
                                            fullwidth
                                            disabled
                                            id="outlined-basic"
                                            label={user.email}
                                            variant="outlined"
                                            style={{ width: "100%" }} />
                                    </div>
                                    <div style={{ marginLeft: 16 }}>
                                        <p>Username</p>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            label={user.username}
                                            variant="outlined"
                                            style={{ width: "100%" }} />
                                    </div>
                                </div>
                                <div className='nameInputContainer'>
                                    <div>
                                        <p>Birthdate</p>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            label={moment(user.birthdate).format('L')}
                                            variant="outlined"
                                            style={{ width: "100%" }} />
                                    </div>
                                    <div style={{ marginLeft: 16 }}>
                                        <p>Phone Number</p>
                                        <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            label={user.phone}
                                            variant="outlined" />
                                    </div>
                                </div>
                                <div className='usernamePassInputContainer'>
                                    <div>
                                        <p>Wallet</p>
                                        <TextField
                                            size="small"
                                            fullwidth
                                            disabled
                                            id="outlined-basic"
                                            label={user.wallet}
                                            variant="outlined"
                                            style={{ width: "100%" }} />
                                    </div>
                                    <div style={{ marginLeft: 16, display: 'flex' }}>
                                        <div>
                                            <p>Password</p>
                                            {/* <TextField
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            label={user.password}
                                            variant="outlined" /> */}
                                            <input
                                                style={{
                                                    fontSize: 14, height: '35px', borderRadius: 4, border: '1px solid #949393', color: '#949393', paddingLeft: 4
                                                }}
                                                type="text"
                                                id="password"
                                                name="password"
                                                onChange={(e) => setPass(e.target.value)}
                                                value={pass}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                            <Button
                                                onClick={() => func()}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </Grid>
            </Grid>
            <Snackbars
                open={successopen}
                text="Password changed"
                severity="success"
                duration={3000}
                close={handleClose} />
        </Container>
    )
}

export default Profile