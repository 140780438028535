import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'

function CryptoCurrency({ coins }) {

    return (
        <>
            <Container style={{ marginTop: '-100px' }}>
                <Grid container fluid >
                    {
                        coins?.slice(0, 4)?.map((item) => (

                            <Grid lg={3} md={3} sm={6} xs={12} className="cryptoDetailWrapper" key={item?.id}>
                                <div className='cryptoDetailContainer'>
                                    <div className='cryptoHeaderContainer'>
                                        <div className='cryptoLogoName'>
                                            <div
                                                className='cryptoLogo'
                                                style={{ backgroundImage: `url(${item?.image})` }}
                                            ></div>
                                            <p className='cryptoName'>{item?.name}/<span>{item?.symbol}</span></p>
                                        </div>
                                        <p className='cryptoCurrency'>${item?.current_price}</p>
                                    </div>
                                    <div className='cryptoBodyContainer'>
                                        <div className='cryptoChange'>
                                            <p className='change'>Change</p>
                                            <p
                                                className={item?.market_cap_change_percentage_24h < 0 ? 'negChange' : 'posChange'}
                                            >
                                                {item?.market_cap_change_percentage_24h}%
                                            </p>
                                        </div>
                                        <div className='cryptoVolume'>
                                            <p className='volume'>Volume</p>
                                            <p className='volumeValue'>{item?.total_volume}$</p>
                                        </div>
                                    </div>
                                    <div className='cryptoFooterContainer'>
                                        <div className='cryptoHigh'>
                                            <p className='high'>24H high</p>
                                            <p className='highValue'>{item?.high_24h}$</p>
                                        </div>
                                        <div className='cryptoLow'>
                                            <p className='low'>24H low</p>
                                            <p className='lowValue'>{item?.low_24h}$</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                        ))
                    }
                </Grid>
            </Container>
        </>
    )
}

export default CryptoCurrency