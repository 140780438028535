import React from 'react'
import './styles.css'
import CryptoCurrency from '../../components/cryptocurrency';
import MobileApp from '../../sections/mobileapp';
import Features from '../../sections/features';
import Blogs from '../../sections/blog';
import Body from '../../sections/body';

function HomePage({ coins }) {

    return (
        <div>
            <Body />
            <CryptoCurrency coins={coins} />
            <MobileApp />
            <Features />
            <Blogs />
        </div>
    )
}

export default HomePage