import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import SignupFields from './signupFields'
import './styles.css'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { useState } from 'react'

function Signup() {
    const [errormessage, setErrorMessage] = useState('')
    return (
        <>
            <Grid container fluid >
                <Grid lg={4} md={4} sm={4} sx={{ display: { xs: 'none', sm: "none", md: 'flex', lg: 'flex' } }} className='SideSignupWrapper' >
                    <Box className="SideSignupContainer" >
                        <h1>Glad to see you!</h1>
                        <p>Enter your personal details<br></br> and start journey with us.</p>

                    </Box>
                </Grid>

                <Grid sx={{ display: { xs: 'flex', sm: "flex" } }} xl={8} lg={8} md={8} xs={12} className="SignupWrapper">

                    <Box className='SignupContainer'>
                        <div className='SignupArrowBack'>
                            <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                                <BsFillArrowLeftCircleFill size={25} style={{ color: '#2C4387' }} />
                            </Link>
                        </div>
                        <div className='SignupContainerChildOne'>
                            <h1>Sign up</h1>
                            {
                                errormessage ?
                                    <p style={{ color: 'green' }}>{errormessage}</p>
                                    :
                                    null
                            }
                            <SignupFields setErrorMessage={setErrorMessage} />
                            <p className='SideSigninSignup'>Already have an account.
                                {" "}
                                <Link to="/signin" style={{ color: 'black' }}>Sign in</Link>.{" "}
                            </p>
                        </div>

                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Signup