import { Chip, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getTransactions } from '../../api';
import './styles.css'
import Cookies from 'universal-cookie'
// import { BsArrowRight } from 'react-icons/bs'
import moment from 'moment/moment';

function Transactions() {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [transactions, setTransactions] = useState([]);

    useEffect(
        () => {
            getTransactions(id).then(async res => {
                // const transactionResult = [];
                // res.data.forEach(element => {
                //     if (element.status === 0) {
                //         transactionResult.push(element)
                //     }
                // });

                setTransactions(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])



    return (
        <Container>
            <Grid container fluid >
                <Grid lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'cetner', justifyContent: 'center' }} >
                    <div className='transactionsWrapper'>
                        <div className='transactionstTitleContainer'>
                            <p className='transactionsTitle'>Transactions</p>
                        </div>
                        <div className='transactionsContainer'>
                            <Table responsive="sm">
                                <TableHead>
                                    <TableRow style={{ textAlign: 'left' }}>
                                        <TableCell align='center'><b>From</b></TableCell>
                                        <TableCell align='center'><b>To</b></TableCell>
                                        <TableCell align='center'><b>Amount</b></TableCell>
                                        <TableCell align='center'><b>Currency</b></TableCell>
                                        <TableCell align='center'><b>Date</b></TableCell>
                                        <TableCell align='center'><b>Status</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        transactions?.map(item => (
                                            <>
                                                <TableRow key={item._id}>
                                                    <TableCell align='center'><p>{cookie.get('username')}</p></TableCell>

                                                    <TableCell align='center'><p>{item.receiver}</p></TableCell>

                                                    <TableCell align='center'><p>{item.amount}</p></TableCell>
                                                    <TableCell align='center'><p>{item.sender.currency}</p></TableCell>
                                                    <TableCell align='center'><p>{moment(item.createAt).format('L')}</p></TableCell>

                                                    <TableCell align='center'>
                                                        {
                                                            item.status === 0 ?
                                                                <>
                                                                    <Chip label="Pending" color="warning" />
                                                                </>
                                                                :
                                                                <>
                                                                    <Chip label="Confirmed" color="success" />
                                                                </>
                                                        }
                                                    </TableCell>
                                                </TableRow>

                                            </>

                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Transactions