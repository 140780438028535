import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import security from '../../assets/images/security.svg'
import speed from '../../assets/images/speed.svg'
import support from '../../assets/images/support.svg'

function Features() {
    const features = [
        {
            id: 1,
            title: 'Our Tax Reporting is Changing',
            subTitle: 'Understand the changes ahead of 1st July 2022'
        },
        {
            id: 2,
            title: 'New Listing: Aave (AAVE)',
            subTitle: 'Start Trading Aave Today!'
        },
        {
            id: 3,
            title: 'Three New Assets Listed',
            subTitle: 'USD Coin (USDC), The Sandbox (SAND) & Decentraland (MANA)'
        },
    ]

    const features2 = [
        {
            id: 1,
            img: security,
            title: 'Security',
            subTitle: 'Multiple layers of account security, 2FA, address whitelisting, Biometrics.'
        },
        {
            id: 2,
            img: support,
            title: 'Support',
            subTitle: '100% Australian-based customer support, OTC desk and VIP Program.'
        },
        {
            id: 3,
            img: speed,
            title: 'Speed',
            subTitle: 'Fast trade executions with an industry leading uptime of 99.99%.'
        },
    ]
    return (
        <>
            <Container style={{ padding: 0 }}>
                <Grid container fluid >
                    {
                        features?.map((item) => (
                            <>
                                <Grid lg={4} md={6} sm={6} xs={12} className="featuresWrapper" >
                                    <div className='featuresContainer'>
                                        <p className='featureTitle'>{item.title}</p>
                                        <p className='featureSubTitle'>{item.subTitle}</p>
                                    </div>
                                </Grid>
                            </>
                        ))
                    }
                    <Grid lg={12} md={12} sm={12} xs={12}>
                        <div className='featuresContainerTwo'>
                            <p className='featuresTitleTwo'>Take control of your future with <br></br>superior technology and security</p>
                        </div>
                    </Grid>
                    {
                        features2?.map((item) => (
                            <Grid lg={4} md={4} sm={4} xs={12}>
                                <div className='technologyContainer'>
                                    <img alt="featureImage" src={item.img} style={{ width: 100, aspectRatio: '1' }} />
                                    <h3 className='technologyTitle'>{item.title}</h3>
                                    <p className='technologySubTitle'>{item.subTitle}</p>
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </>
    )
}

export default Features