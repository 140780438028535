export const about = [
    {
        id: 1,
        title: 'Our story'
    },
    {
        id: 2,
        title: 'Features'
    },
    {
        id: 3,
        title: 'Mobile App'
    },
    {
        id: 4,
        title: 'Staking'
    },
    {
        id: 5,
        title: 'OTC Trading'
    },
    {
        id: 6,
        title: 'Contact Us'
    },
    {
        id: 7,
        title: 'Blog'
    },


];

export const legal = [
    {
        id: 1,
        title: 'Privacy Policy'
    },
    {
        id: 2,
        title: 'Terms of Service'
    },
    {
        id: 3,
        title: 'Terms of Service'
    },
    {
        id: 4,
        title: 'AML/CTF Policy'
    },
    {
        id: 5,
        title: 'Information Requests'
    },
];
export const help = [
    {
        id: 1,
        title: 'Support'
    },
    {
        id: 2,
        title: 'Complaints'
    },
    {
        id: 3,
        title: 'FAQs'
    },
    {
        id: 4,
        title: 'Fees'
    },
    {
        id: 5,
        title: 'Bug Bounty'
    },
    {
        id: 6,
        title: 'API'
    },
    {
        id: 7,
        title: 'Tax Reporting'
    },
]

