import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import ContactUsFields from './contactusfields'
import './styles.css'
function Modale({ handleCloseModal, openModal }) {
    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='ContactUsTitleContainer'>
                        <h2>Contact Us</h2>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ContactUsFields />
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus>
                        Submit
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
}

export default Modale