import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './styles.css'
import moment from 'moment';
import { Container } from '@mui/system';


export default function CoinTable({ coins }) {

    return (
        <Container style={{ padding: 0 }}>

            <TableContainer component={Paper} style={{ marginTop: 64, marginBottom: 128 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" id="myTable">
                    <TableHead >
                        <TableRow>
                            <TableCell style={{ fontWeight: 700 }}>Coin</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">%Change</TableCell>
                            <TableCell align="center">Market Cap</TableCell>
                            <TableCell align="center">volume 24h</TableCell>
                            <TableCell align="center">Last Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {coins?.map((item) => (
                            <TableRow
                                key={item?.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <div className='cryptoMarketLogoName'>
                                        <div
                                            className='cryptoMarketLogo'
                                            style={{ backgroundImage: `url(${item?.image})` }}
                                        ></div>
                                        <p className='cryptoMarketName'>{item?.name}/<span>{item?.symbol}</span></p>
                                    </div>
                                </TableCell>
                                <TableCell align="center">{item?.current_price} $</TableCell>
                                <TableCell align="center">
                                    <p
                                        className={item?.market_cap_change_percentage_24h < 0 ? 'negChange' : 'posChange'}
                                    >
                                        {item?.market_cap_change_percentage_24h} %
                                    </p>
                                </TableCell>
                                <TableCell align="center">{item?.market_cap}$</TableCell>
                                <TableCell align="center">{item?.total_volume}$</TableCell>
                                <TableCell align="center">{moment(item.last_updated).format('MMMM Do YYYY, h:mm a')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
    );
}
