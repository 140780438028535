import { Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { legal, about, help } from './footerData'
import './styles.css'
import Cookies from 'universal-cookie';
import SocialMedia from './socialMedia'
import { Link } from 'react-router-dom'

function Footer() {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <Container>
                <Grid container fluid >
                    {
                        cookie.get('token') ? null :
                            <Grid lg={12} md={12} sm={12} xs={12}>
                                <div className='floatingContainer'>
                                    <p>Start building your future today</p>
                                    <Button
                                        variant="contained"
                                        className='createAccount'
                                    >
                                        <Link to="/signup" style={{ textDecoration: 'none', color: 'white' }}>Create Account</Link>
                                    </Button>
                                </div>
                            </Grid>
                    }
                    <Grid lg={12} md={12} sm={12} xs={12} style={{ padding: '64px 32px 32px 32px' }}>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                marginBottom: ' 16px',
                                mr: 1,
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'white',
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div className='logo'></div>
                        </Typography>
                    </Grid >
                    {/* <Grid lg={6} md={6} sm={6} xs={6}>
                        <SocialMedia />
                    </Grid> */}
                    <Grid lg={4} md={4} sm={4} xs={6}>
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>ABOUT</h5>
                        {
                            cookie.get('token') ?
                                about?.map((item) => (
                                    <p key={item.title} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                ))
                                :
                                about?.map((item) => (
                                    <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
                                        <p key={item.title} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                    </Link>
                                ))
                        }
                    </Grid>
                    <Grid lg={4} md={4} sm={4} xs={6} className="legal">
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>LEGAL</h5>
                        {
                            cookie.get('token') ?
                                legal?.map((item) => (
                                    <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                ))
                                :
                                legal?.map((item) => (
                                    <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
                                        <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                    </Link>
                                ))
                        }
                    </Grid>
                    <Grid lg={4} md={4} sm={4} xs={6} className="help">
                        <h5 style={{ margin: '0px 0px 24px 0px', color: 'white' }}>HELP</h5>
                        {
                            cookie.get('token') ?
                                help?.map((item) => (
                                    <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                ))
                                :
                                help?.map((item) => (
                                    <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
                                        <p key={item.id} style={{ color: 'white', fontSize: 14, cursor: 'pointer', }}>{item.title}</p>
                                    </Link>

                                ))
                        }
                    </Grid>

                    <Grid lg={12} md={12} sm={12} xs={12}>
                        <div className='FooterDownloadAppContainer'>
                            <div style={{ marginTop: 64 }}>
                                <p>© Copy Right-2022 All rights reserved.</p>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Footer