import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import SigninFields from './signinFields'
import './styles.css'

function Signin() {
    const [errormessage, setErrormessage] = useState('')
    return (
        <>
            <Grid container fluid >
                <Grid sx={{ display: { xs: 'flex', sm: "flex" } }} lg={7} md={8} xs={12} className="SigninWrapper">
                    <div className='SigninContainer'>
                        <div className='SigninArrowBack'>
                            <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                                <BsFillArrowLeftCircleFill size={25} style={{ color: '#2C4387' }} />
                            </Link>
                        </div>
                        <div className='SigninContainerChildOne'>
                            <h1>Sign in</h1>
                            {
                                errormessage ?
                                    <p style={{ margin: 0, color: 'red' }}>{errormessage}</p>
                                    :
                                    null
                            }
                            <SigninFields setErrormessage={setErrormessage} />
                            <p className='SideSigninSignup'>Don't have an account.
                                {" "}
                                <Link to="/signup" style={{ color: 'black' }}>Sign up</Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid lg={5} md={4} sm={4} sx={{ display: { xs: 'none', sm: "none", md: 'flex', lg: 'flex' } }} className='SideSigninWrapper' >
                    <div className="SideSigninContainer" >
                        <h1>Welcome Back!</h1>
                        <p className='SideSigninSubTitle'>To keep connected with us please<br></br> login in with your personal info.</p>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Signin