import { Container, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import cryotocurrency from '../../assets/images/cryptocurrency-removebg-preview.png';

function MobileApp() {
    return (
        <>
            <Container style={{ padding: 0 }}>
                <Grid container fluid >
                    <Grid lg={6} md={6} sm={12} xs={12} className="mobileAppWrapper">
                        <div className='mobileAppContainer'>
                            <p className='mobileApp'>what's a cryptocurrency</p>
                            <p className='mobileParag'>
                                A cryptocurrency is a digital currency, which is an alternative form of
                                payment created using encryption algorithms. The use of encryption technologies means that cryptocurrencies
                                function both as a currency and as a virtual accounting system. To use cryptocurrencies,
                                you need a cryptocurrency wallet.
                            </p>

                        </div>
                    </Grid>
                    <Grid lg={6} md={6} sm={12} xs={12} className="appImageContainer">
                        <div className='appImage'>
                            <img src={cryotocurrency} alt='cryptoImage' />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default MobileApp